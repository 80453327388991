import styles from './PLP.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import { sanitizeId } from 'utils/helpers';
import ProductContext from './ProductContext';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { SimpleText } from 'components/sanaText';
import Stock from './Stock';
import Specifications from './Specifications';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';
import WithProductRoute from './WithProductRoute';
import TotalChildren from './TotalChildren';

const Description = ({ id, url, title, isParent, tradeMark, gridMode, showTradeMark }) => {
  const isPreview = useIsPreview();

  return (
    <div className={styles.productDescription}>
      {(gridMode && showTradeMark) && (
        <div>
          <span className={styles.productTrademark}> {tradeMark}</span>
        </div>
      )}     
      {isPreview && !url
        ? <Placeholder className={styles.titlePreviewPlaceholder} />
        : (
          <WithProductClickTracking>
            {handleClick => (
              <WithProductRoute product={{ id, url }}>
                {(productUrl, productRouteBuilder) => (
                  <Link onClick={handleClick} url={productUrl} to={productRouteBuilder} className={styles.productTitle}>                    
                    <h2 id={sanitizeId('title' + id)}>
                      {title}
                      {/*Use dot for screen readers to add pause after the title.*/}
                      <i className="visually-hidden">.</i>
                    </h2>
                    </Link>
                )}
              </WithProductRoute>
            )}
          </WithProductClickTracking>
        )
      }
      {(!gridMode && showTradeMark) && (
        <div>
          <span className={styles.productTrademark}> {tradeMark}</span>
        </div>
      )} 
      {!isParent && (
        <div className={styles.productIdStock}>         
          <Stock />
        </div>
      )}
      {!isParent && (
        <Specifications />
      )}
      {isParent && (
        <TotalChildren />
      )}      
    </div>
  );
};

Description.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isParent: PropTypes.bool,
  tradeMark: PropTypes.string,
  gridMode: PropTypes.bool,
  showTradeMark: PropTypes.bool,
};

export default connectToContext([ProductContext],
  ({ id, url, title, isParent, tradeMark }) => ({ id, url, title, isParent, tradeMark }),
)(
  memo(Description),
);