exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductSuggestions_suggestions-btn{display:flex;justify-content:center;align-items:center;margin:0 var(--gutterWidth,16px) 15px;padding:7px 10px;min-width:auto;min-height:auto}.ProductSuggestions_suggestions-btn .ProductSuggestions_icon{max-width:.28em;max-height:.28em}.ProductSuggestions_suggestions-btn.ProductSuggestions_hide-label{display:inline-flex;padding:0;margin:0;width:44px;height:44px}.ProductSuggestions_suggestions-btn.ProductSuggestions_hide-label .ProductSuggestions_icon{max-width:20px;max-height:20px}.ProductSuggestions_suggestions-btn .btn-cnt{margin-left:.6em;padding-top:0}", ""]);

// exports
exports.locals = {
	"suggestions-btn": "ProductSuggestions_suggestions-btn",
	"suggestionsBtn": "ProductSuggestions_suggestions-btn",
	"icon": "ProductSuggestions_icon",
	"hide-label": "ProductSuggestions_hide-label",
	"hideLabel": "ProductSuggestions_hide-label"
};