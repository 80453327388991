import styles from './WishList.module.scss';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import NonOrderable from 'components/objects/basket/nonOrderable';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import {
  trackProductListView,
  EVENT_SOURCES,
  getProductsTrackingDataFromLines,
} from 'behavior/analytics';
import { useIsMobileViewport } from 'utils/hooks';
import { useLoadEffect } from 'utils/hooks';
import Line from './Line';
import GroupedProductLine from './GroupedProductLine';

function WishList({ data, trackProductListView }) {
  const { productLines, nonOrderableLines } = data;
  const notLoaded = !productLines;
  const isMobile = useIsMobileViewport();
  const TableBody = isMobile ? Fragment : 'tbody';
  const [showPrice, showUom] = useHasAbilities(AbilityTo.ViewPrices, AbilityTo.ViewUnitOfMeasure);

  useLoadEffect(() => {
    if (notLoaded || !productLines.length)
      return;

    trackProductListView(getProductsTrackingDataFromLines(productLines), EVENT_SOURCES.wishList);
  }, [notLoaded]);

  if (!productLines || !productLines.length)
    return (
      <>
        <div className="msg-block">
          <SimpleText textKey="WishList_EmptyDescription" />
        </div>
      </>
    );

  const nonOrderable = <NonOrderable nonOrderableLines={nonOrderableLines} />;

  return (
    <>
      <div crole="list">
        {nonOrderable}
        <table className={styles.wishList}>
          <thead className={isMobile ? 'visually-hidden' : null}>
            <tr>
              <th colSpan={2} className={styles.title}>
                <SimpleText textKey="General_Product_Title" />
              </th>
              <th className={styles.price} aria-hidden={!showPrice}>
                {showPrice &&
                  <SimpleText textKey="General_Product_Price" />
                }
              </th>
              <th className={styles.colUom} aria-hidden={!showUom}>
                {showUom &&
                  <SimpleText textKey="UOM" />
                }
              </th>
              <th />
            </tr>
          </thead>
          <TableBody>
            {
              productLines.map(line => {
                const { id: lineId, subLines, product } = line;

                if (subLines && subLines.length) {
                  return (
                    <GroupedProductLine
                      key={lineId}
                      product={product}
                      line={line}
                    />
                  );
                }

                return (
                  <Line
                    key={lineId}
                    productLine={line}
                    product={product}
                  />
                );
              })
            }
          </TableBody>
        </table>
      </div>
    </>
  );
}

WishList.propTypes = {
  data: PropTypes.shape({
    productLines: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      sublines: PropTypes.array,
      product: PropTypes.object.isRequired,
    })),
    nonOrderableLines: PropTypes.array,
  }),
  trackProductListView: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  trackProductListView,
};

export default connect(null, mapDispatchToProps)(WishList);

