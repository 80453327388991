import styles from './Footer.module.scss';
import simpleNavStyles from 'components/objects/navigation/SimpleNavigation.module.scss';
import { useCallback, useRef, memo } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Col, Container, Row } from 'components/primitives/grid';
import { useSanaTexts, useSimpleTexts } from 'components/sanaText';
import { SubscribeInlineColumn } from 'components/objects/newsletter';
import { SimpleNavigation, useLoadNavigation } from 'components/objects/navigation';
import { NavigationKeys } from 'behavior/navigation/constants';
import { Copyright } from 'components/primitives/copyright';
import { useSelector } from 'react-redux';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { useMatchMediaContext } from 'components/responsive';

const textsToLoad = ['Aria_Sitemap_Navigation', 'Aria_Footer_Navigation'];

const FooterTemplate = () => {
  const shopName = useSelector(s => s.settings.shopName);
  const sitemapRef = useRef();
  const { xs } = useMatchMediaContext();
  const [hasSubscribeColumn] = useHasAbilities(AbilityTo.SubscribeToNewsletter);
  const hasSitemap = useLoadNavigation(NavigationKeys.Sitemap);
  const hasFooterNav = useLoadNavigation(NavigationKeys.Footer);

  const [ariaSitemapNavigationText, ariaFooterNavigationText] = useSanaTexts(textsToLoad, stripHtmlTagsAndFormatting).texts;
  const [sitemapTitle] = useSimpleTexts(['SiteMapContainer_Title']).texts;

  const handleSitemapResize = useCallback(() => {
    for (const { offsetTop } of sitemapRef.current.children) {
      if (offsetTop !== 0) {
        sitemapRef.current.classList.add(simpleNavStyles.fixedWidth);
        return;
      }
    }

    sitemapRef.current.classList.remove(simpleNavStyles.fixedWidth);
  }, []);

  return (
    <Container>
      {(hasSitemap || hasSubscribeColumn) &&
        <Row className={styles.topRow}>
          {hasSitemap &&
            <Col xs className={styles.sitemapCol}>
              <div>
                {!xs &&
                  <ReactResizeDetector
                    targetRef={sitemapRef}
                    handleWidth
                    onResize={handleSitemapResize}
                    refreshMode="throttle"
                    refreshRate={250}
                  />
                }
                <SimpleNavigation
                  groupCode={NavigationKeys.Sitemap}
                  className={`${simpleNavStyles.sitemap} clearfix`}
                  ariaLabel={ariaSitemapNavigationText}
                  ref={sitemapRef}
                  isFooterCustom
                >
                  <h2 className={`${styles.title} ${styles.sitemapTitle} h4`}>{sitemapTitle}</h2>
                </SimpleNavigation>
              </div>
            </Col>
          }
          {hasSubscribeColumn && <SubscribeInlineColumn hasSitemap={hasSitemap} />}
        </Row>
      }
      {(shopName || hasFooterNav) &&
        <Row className={styles.bottomRow}>
          {shopName && <Col><Copyright shopName={shopName} /></Col>}
          {hasFooterNav &&
            <Col xs={12} sm={6}>
              <SimpleNavigation
                groupCode={NavigationKeys.Footer}
                className={simpleNavStyles.nav}
                ariaLabel={ariaFooterNavigationText}
              />
            </Col>
          }
        </Row>
      }
    </Container>
  );
};

export default memo(FooterTemplate);
