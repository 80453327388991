import styles from '../Details.module.scss';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { Select, Swatcher } from 'components/primitives/form';
import { usePrintMode } from 'utils/hooks';
import { focusById } from 'utils/dom';

const VariantDropdownRow = ({ id, value, groupName, variantComponents, onChange, variantComponentsFull, isSwatcher }) => {
  const isPrintMode = usePrintMode();
  const items = variantComponents.map(c => ({ name: c.name, value: c.id }));
  const selectedItem = items.find(item => item.value === value);

  const labelId = id + '_lb';

  return (
    <Row className={styles.row} crossAxisAlign="center">
     
      <Col xs={12}>
        {isPrintMode && <span className={styles.variant}>{selectedItem.name}</span>}
        {!isPrintMode && isSwatcher &&
          <Swatcher
            items={items}
            onChange={onChange}
            value={value}
            id={id}
            className={styles.variant}
            optionClassName={styles.variantOption}
            variantComponentsFull={variantComponentsFull}
          />
        }
        {!isPrintMode && !isSwatcher &&
          <Select
            items={items}
            onChange={onChange}
            value={value}
            id={id}
            labelId={labelId}
            className={styles.variant}
            optionClassName={styles.variantOption}
          />
        }
      </Col>
    </Row>
  );
};

VariantDropdownRow.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  variantComponents: PropTypes.array.isRequired,  
  onChange: PropTypes.func,
  variantComponentsFull: PropTypes.array,
  isSwatcher: PropTypes.bool,
};

export default VariantDropdownRow;