import { createReducer } from 'utils/redux';
import {
  PRODUCT_CONFIGURATOR_CONFIGURATION_FAILED,
  PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_RECEIVED,
  PRODUCT_CONFIGURATOR_EDIT_CONFIGURATION_RECEIVED,
  PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED,
  PRODUCT_CONFIGURATOR_FINISHED,
} from './actions';

export default createReducer(null, {
  [PRODUCT_CONFIGURATOR_CONFIGURATION_FAILED]: configurationFailed,
  [PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_RECEIVED]: newConfigurationReceived,
  [PRODUCT_CONFIGURATOR_EDIT_CONFIGURATION_RECEIVED]: editConfigurationReceived,
  [PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED]: messageReceived,
  [PRODUCT_CONFIGURATOR_FINISHED]: configuratorFinished,
});

function configurationFailed(state, action) {
  return {
    ...state,
    productConfigurator: {
      updatedById: action.payload,
    },
  };
}

function newConfigurationReceived(state, action) {
  return {
    ...state,
    productConfigurator: {
      configuratorUrl: action.payload.configuratorUrl,
      configurationId: action.payload.configurationId,
    },
  };
}

function editConfigurationReceived(state, action) {
  return {
    ...state,
    productConfigurator: {
      configuratorUrl: action.payload.configuratorUrl,
      configurationId: action.payload.configurationId,
      editedBasketLineId: action.payload.basketLineId,
    },
  };
}

function messageReceived(state, { payload: { message } }){
  if (!message?.length)
    return state;

  return {
    ...state,
    productConfigurator: {
      ...state.productConfigurator,
      message,
    },
  };
}

function configuratorFinished(state, _) {
  if (!state.productConfigurator) {
    return state;
  }

  return {
    ...state,
    productConfigurator: null,
  };
}
