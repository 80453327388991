exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductComparisonPage_actions-section{border-bottom:1px solid var(--tables_BorderColor,#e6e6e6);display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;padding:15px 0 2px}", ""]);

// exports
exports.locals = {
	"actions-section": "ProductComparisonPage_actions-section",
	"actionsSection": "ProductComparisonPage_actions-section"
};