export const ShippingAddressOption = Object.freeze({
  Billing: 'BILLING',
  Existing: 'EXISTING',
  Custom: 'CUSTOM',
  PickupStore: 'PICKUP_STORE',
});

export const CheckoutProcessNames = Object.freeze({
  Order: 'ORDER',
  Quote: 'QUOTE',
  EditOrder: 'EDIT_ORDER',
  EditQuote: 'EDIT_QUOTE',
  Invoice: 'INVOICE',
  Promotion: 'QuotePromotion',
});

export const Steps = Object.freeze({
  Address: 'ADDRESS',
  Shipping: 'SHIPPING',
  Payment: 'PAYMENT',
  CustomerData: 'ADDITIONAL_CUSTOMER_DATA',
  ExtraPayment: 'EXTRA_PAYMENT',
  Overview: 'OVERVIEW',
  None: 'N/A',
});

export const TermsAndAgreementsVisibility = Object.freeze({
  Invisible: 'INVISIBLE',
  VisibleNotSelected: 'VISIBLE_NOT_SELECTED',
  VisibleSelected: 'VISIBLE_SELECTED',
});
