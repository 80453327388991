import BreadcrumbPrimitive from 'components/primitives/breadcrumb';
import { routesBuilder } from 'routes';
import { useSimpleTexts } from 'components/sanaText';

const BasketBreadcrumb = () => {
  const [shoppingBasketHeaderText] = useSimpleTexts(['ShoppingBasket_Header']).texts;

  const basketNode = {
    title: shoppingBasketHeaderText || '',
    link: { to: routesBuilder.forBasket() },
  };

  return <BreadcrumbPrimitive items={[basketNode]} />;
};

export default BasketBreadcrumb;
