import styles from './ProductTiles.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';

const TotalChildren = ({ totalChildren }) => {
  if (!totalChildren)
    return null;

  return (
    <div className={styles.productId}>
      <span>
        <SimpleText
          textKey="General_Product_TotalChildren"
          formatWith={[totalChildren]}
          placeholder={<Placeholder className={styles.namePlaceholder} />}
          formatAsHtml
        />
      </span>
    </div>
  );
};

TotalChildren.propTypes = {
  totalChildren: PropTypes.number,
};

export default TotalChildren;