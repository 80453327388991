import styles from './ProductPrice.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import { renderHTML, makeSimpleText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { getCurrency, getFormatPrice, formatAsPercentage } from 'utils/format';
import { usePriceModel } from 'utils/product';
import { useCurrencyInfo } from 'utils/hooks';

const LowestPrice = ({ salesPrice, basePrice }) => {
  const currencyInfo = useCurrencyInfo();
  const { price, actionPrice } = usePriceModel(salesPrice, basePrice);
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);

  const formattedPrice = useMemo(() => {
    if (!currencyInfo)
      return null;

    return renderHTML(formatPrice(formatAsPrice(price), currencyInfo));
  }, [currencyInfo, price]);

  const { texts: [
    priceLbl,
  ], loaded } = useSanaTexts([
    'FromPrice',
  ]);

  if (!loaded || formattedPrice == null)
    return <Placeholder className="price-block product-price-placeholder" />;

  const currencySymbol = getCurrency(currencyInfo.id, currencyInfo.cultureName);

  return (
    <Col xs="auto" className="price-block" title={priceLbl}>
      <i className="visually-hidden">{priceLbl} {currencySymbol + price}</i>
      <span className={`${styles.actualPrice} actual-price`} aria-hidden suppressHydrationWarning>
        {priceLbl} {formattedPrice}
      </span>
    </Col>
  );
};

LowestPrice.propTypes = {
  salesPrice: PropTypes.number,
  basePrice: PropTypes.number,
};

export default memo(props => {
  if (props.salesPrice === null)
    return null;

  if (typeof props.salesPrice === 'undefined')
    return <Placeholder className="price-block product-price-placeholder" />;

  return <LowestPrice {...props} />;
});

function formatPrice(price, currencyInfo) {
  if (currencyInfo.decimalDigits === 0)
    return price;

  return price.replace(/(\d+)([^\d]*)$/, '<sup>$1</sup>$2');
}
