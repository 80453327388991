import styles from '../Checkout.module.scss';
import { memo, useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ShippingAddressOption, selectAddress, selectPickupStore, saveAddress } from 'behavior/pages/checkout';
import { extractEscapedTemplateFields, validateForm } from 'components/objects/forms';
import StepButtons from './StepButtons';
import { AddressStep as AddressStepSection, AddressStepBody } from '../base/address';
import CheckoutContext from './CheckoutContext';
import scrollIntoView from 'scroll-into-view';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { useOnChange } from 'utils/hooks';
import InvalidAddressMessage from '../base/InvalidAddressMessage';

const AddressStep = ({
  info: {
    shippingAddresses,
    billingAddress,
    shippingAddress,
    shippingTemplateFields,
    isQuote,
    quote,
    stepInvalid,
    isValidShippingAddress,
    invalidProductsForShippingAddress,
    pickupStoreAddresses,
  },
  isActive,
  isCompleted,
}) => {
  const { nextStep } = useContext(CheckoutContext);
  const formikRef = useRef();
  formikRef.current = null;
  const optionSelectedRef = useRef();

  const dispatch = useDispatch();

  const { address: selectedShipping, shippingOption: selectedShippingOption } = shippingAddress;

  const [lastSelected, setLastSelected] = useState(() => {
    if (selectedShippingOption === ShippingAddressOption.Existing)
      return selectedShipping && selectedShipping.id;
  });

  const [lastPickupSelected, setLastPickupSelected] = useState(() => {
    if (selectedShippingOption === ShippingAddressOption.PickupStore)
      return selectedShipping && selectedShipping.id;
  });

  const [showSelectionValidation, setShowSelectionValidation] = useState();

  const [lastCustomFields, setLastCustomFields] = useState(() => {
    if (selectedShippingOption === ShippingAddressOption.Custom)
      return selectedShipping && selectedShipping.fields;
  });

  const shippingOptionState = useState(selectedShippingOption);
  const [shippingOption, setShippingOption] = shippingOptionState;
  useOnChange(() => setShippingOption(selectedShippingOption), [selectedShippingOption], false);

  useEffect(() => {
    setShowSelectionValidation(stepInvalid);

    if (selectedShippingOption === ShippingAddressOption.Existing) {
      setLastSelected(selectedShipping && selectedShipping.id);
      return;
    }

    setLastSelected(null);

    if (selectedShippingOption === ShippingAddressOption.PickupStore) {
      setLastPickupSelected(selectedShipping && selectedShipping.id);
      return;
    }

    setLastPickupSelected(null);

    if (selectedShippingOption === ShippingAddressOption.Custom)
      setLastCustomFields(selectedShipping && selectedShipping.fields);
  }, [shippingAddresses, selectedShipping, isActive, stepInvalid]);

  const isBilling = shippingOption === ShippingAddressOption.Billing;
  const isExisting = shippingOption === ShippingAddressOption.Existing;
  const isCustom = shippingOption === ShippingAddressOption.Custom;
  const isPickupStore = shippingOption === ShippingAddressOption.PickupStore;

  const canUseBilling = useHasAbilities(AbilityTo.ShipToBillingAddress)[0];
  useEffect(() => {
    const isBillingAddressAvailable = canUseBilling && !!billingAddress,
      isShippingAddressesAvailable = shippingAddresses && shippingAddresses.length > 0,
      isCustomAddressAvailable = !!(shippingTemplateFields && shippingTemplateFields.length),
      isPickupStoresAvailable = pickupStoreAddresses && pickupStoreAddresses.length > 0;

    if (isBilling && isBillingAddressAvailable || isExisting && isShippingAddressesAvailable || isCustom && isCustomAddressAvailable || isPickupStore && isPickupStoresAvailable)
      optionSelectedRef.current = true;

    if (selectedShippingOption === ShippingAddressOption.Custom && !isCustomAddressAvailable) {
      if (isBillingAddressAvailable)
        setShippingOption(ShippingAddressOption.Billing);
      else if (isShippingAddressesAvailable)
        setShippingOption(ShippingAddressOption.Existing);
    }
  }, [selectedShippingOption, shippingTemplateFields, canUseBilling]);

  const onAddressSelection = id => {
    setShowSelectionValidation(false);
    setLastSelected(id);
  };

  const onAddressPickupSelection = id => {
    setShowSelectionValidation(false);
    setLastPickupSelected(id);
  };

  const onOptionChange = () => {
    optionSelectedRef.current = true;
    setShowSelectionValidation(false);

    if (isCustom) {
      const formik = formikRef.current;

      if (formik && formik.dirty)
        setLastCustomFields(extractEscapedTemplateFields(formik.values, shippingTemplateFields));
    }
  };

  const onStepSubmit = async () => {
    if (!optionSelectedRef.current) {
      showValidationMsg();
      return;
    }

    if (isBilling) {
      submitStep(selectAddress());
      return;
    }

    if (isExisting) {
      if (lastSelected || shippingAddresses.length === 1) {
        submitStep(selectAddress(lastSelected || shippingAddresses[0].id));
        return;
      }

      showValidationMsg();
    }

    if (isPickupStore) {
      if (lastPickupSelected || pickupStoreAddresses.length === 1) {
        submitStep(selectPickupStore(lastPickupSelected || pickupStoreAddresses[0].id));
        return;
      }

      showValidationMsg();
    }

    if (isCustom) {
      if (!(await validateForm(formikRef))) {
        setShowSelectionValidation(true);
        const invalidElement = document.querySelector(`.${styles.activeStep} [aria-invalid=true]`);

        if (invalidElement)
          scrollIntoView(invalidElement, { time: 300 }, invalidElement.focus && (() => invalidElement.focus()));

        return;
      }

      const formData = extractEscapedTemplateFields(formikRef.current.values, shippingTemplateFields);
      submitStep(saveAddress(formData));
    }
  };

  const onAddressFormValidate = valid => {
    if (valid)
      setShowSelectionValidation(false);
  };

  return (
    <AddressStepSection
      asLink={!isActive}
      className={`${styles.address} ${isActive ? styles.activeStep : ''}`}
      isQuote={isQuote}
      isPromotion={!!quote}
      isCompleted={isCompleted}
    >
      <InvalidAddressMessage isValid={isValidShippingAddress} invalidProducts={invalidProductsForShippingAddress} />
      {isActive &&
        <>
          <AddressStepBody
            shippingOptionState={shippingOptionState}
            selectedAddressId={lastSelected}
            selectedPickupAddressId={lastPickupSelected}
            shippingAddresses={shippingAddresses}
            billingAddress={billingAddress}
            templateFields={shippingTemplateFields}
            customFields={lastCustomFields}
            onOptionChange={onOptionChange}
            onAddressSelection={onAddressSelection}
            onPickupStoreSelection={onAddressPickupSelection}
            onAddressFormValidate={onAddressFormValidate}
            validateAddressFormOnBlur
            onAddressFormSubmit={onStepSubmit}
            formikRef={formikRef}
            showSelectionValidation={showSelectionValidation}
            pickupStoreAddresses={pickupStoreAddresses}
          />
          <StepButtons onStepSubmit={onStepSubmit} />
        </>
      }
    </AddressStepSection>
  );

  function submitStep(action) {
    nextStep();
    dispatch(action);
  }

  function showValidationMsg() {
    setShowSelectionValidation(true);
    setTimeout(() => scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 200 }), 10);
  }
};

AddressStep.propTypes = {
  info: PropTypes.shape({
    stepInvalid: PropTypes.bool,
    shippingAddresses: PropTypes.array,
    billingAddress: PropTypes.shape({
      formatted: PropTypes.string,
    }),
    shippingAddress: PropTypes.shape({
      address: PropTypes.object,
      shippingOption: PropTypes.string,
    }),
    shippingTemplateFields: PropTypes.array,
    isQuote: PropTypes.bool,
    quote: PropTypes.object,
  }).isRequired,
  isActive: PropTypes.bool,
  isCompleted: PropTypes.bool,
};

export default memo(AddressStep);
