import styles from './SubscribeInlineColumn.module.scss';
import footerStyles from 'components/sections/footer/Footer.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import { connect } from 'react-redux';
import { SimpleText, RichText, useSimpleTexts, useSanaTexts } from 'components/sanaText';
import { subscribeToNewsletter } from 'behavior/newsletter';
import { SanaForm, FieldError } from 'components/objects/forms';
import { EmailAddressField } from 'components/objects/forms/fields';
import { SanaButton } from 'components/primitives/buttons';
import { useSuccessMessage } from './hooks';
import { generateKey } from 'utils/helpers';
import { makeSimpleText, makeRichText } from 'utils/render';
import CheckboxFormGroup from '../termsAndConditions';

const SubscribeInlineColumn = ({
  lastUpdated,
  updatedById,
  subscribeToNewsletter,
  hasSitemap,
}) => {
  const [componentId] = useState(generateKey);
  const subscribedByComponent = componentId === updatedById;
  const { termsError, loaded, title, ...infoTexts } = useTermsAndConditionsTexts();

  const onSubmit = useCallback(formData => {
    delete formData.acceptTerms;
    subscribeToNewsletter(formData, componentId);
  }, []);
  useSuccessMessage(lastUpdated, subscribedByComponent, 'NewsletterSubscribedSuccessfully');

  const [emailTitle] = useSimpleTexts(['EmailAddress']).texts;

  return (
    <Col xs={12} sm={hasSitemap ? 6 : 8} md={5} lg={4} xl={3} className={styles.col}>
      <h2 className={`${footerStyles.title} h4`}>
        <SimpleText textKey="NewsletterContainer_Title" />
      </h2>
      <div className={styles.caption}>
        <RichText textKey="NewsletterContainer_Description" />
      </div>
      <SanaForm
        name="NewsletterInline"
        onSubmit={onSubmit}
        shouldReset={subscribedByComponent}
        className={styles.form}
        validateOnBlur={false}
        onBlur={(e, formik) => {
          if (!e.currentTarget.contains(e.relatedTarget || document.activeElement))
            formik.setFieldError('email', undefined);
        }}
        initialValues={{email:'', acceptTerms:false}}
      >
        <div className={styles.formFieldWrapper}>
          <table>
            <tbody>
              <tr>
                <td>
                  <EmailAddressField
                    fieldName="email"
                    fieldTitle={emailTitle}
                    placeholder={emailTitle}
                    maxLength={80}
                    required
                    validation={{
                      required: true,
                    }}
                  />
                  <div className={styles.validation}>
                    <FieldError fieldName="email" />
                  </div>
                </td>
                <td className={styles.w115}>
                  <SanaButton textKey="Subscribe" type="submit" className={`${btnStyles.btnSmall} ${styles.btnSubmit}`} />
                </td>
              </tr>
              <tr>
                <td>
                  <CheckboxFormGroup
                    fieldName="acceptTerms" isFromSubscribe
                    errorText={termsError} title={title} infoTexts={infoTexts}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </SanaForm>
    </Col>
  );
};

SubscribeInlineColumn.propTypes = {
  subscribeToNewsletter: PropTypes.func.isRequired,
  lastUpdated: PropTypes.instanceOf(Date),
  updatedById: PropTypes.string,
  hasSitemap: PropTypes.bool,
};

export default connect(
  ({ newsletter }) => ({
    lastUpdated: newsletter.lastUpdated,
    updatedById: newsletter.updatedById,
  }),
  { subscribeToNewsletter },
)(SubscribeInlineColumn);

function useTermsAndConditionsTexts() {
  const {
    texts: [
      termsErrorText,
      titleText,
      titleLinkText,
      popupHeaderText,
      popupBodyText,
    ],
    loaded,
  } = useSanaTexts([
    'OrderOverview_AgreeAdditionalTermsError',
    'OrderOverview_AdditionalTerms',
    'TermsAndConditionsAdditional',
    'TermsAndConditionsAdditional_Title',
    'TermsAndConditionsAdditional_Body',
  ]);
  return {
    termsError: makeSimpleText(termsErrorText),
    title: makeSimpleText(titleText),
    linkText: makeSimpleText(titleLinkText),
    popupHeader: makeSimpleText(popupHeaderText),
    popupBody: makeRichText(popupBodyText),
    loaded,
  };
}