import breadcrumbStyles from 'components/primitives/breadcrumb/Breadcrumb.module.scss';
import PropTypes from 'prop-types';

const BackLinkWrapper = ({ children, noTopMargin }) => (
  <div className={'d-print-none ' + (noTopMargin ? '' : breadcrumbStyles.topMargin)}>
    {children}
  </div>
);

BackLinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  noTopMargin: PropTypes.bool,
};

export default BackLinkWrapper;
