exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Swatcher_component-wrapper{display:flex;flex-wrap:wrap;max-width:400px}.Swatcher_component{margin-top:8px;margin-right:8px;height:85px;width:90px;line-height:20px;text-align:center;box-sizing:border-box;cursor:pointer;border:1px solid #c9c9c9;border-radius:5px;font-size:12px;display:flex;align-items:center;justify-content:center}.Swatcher_component.Swatcher_img-wrapper{width:70px;height:70px}.Swatcher_component.Swatcher_img-wrapper img{height:100%;width:100%;object-fit:contain;opacity:.4;filter:alpha(opacity=40)}.Swatcher_component.Swatcher_img-wrapper.Swatcher_selected{border-color:#1b6eb6;background-color:#fff}.Swatcher_component.Swatcher_img-wrapper.Swatcher_selected img{opacity:1;filter:alpha(opacity=100)}.Swatcher_component.Swatcher_selected{border-color:#1b6eb6;background-color:#0078d7;color:#fff}.Swatcher_component.Swatcher_disabled{cursor:default;background-color:#e6e6e6}.Swatcher_noselect{-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none}", ""]);

// exports
exports.locals = {
	"component-wrapper": "Swatcher_component-wrapper",
	"componentWrapper": "Swatcher_component-wrapper",
	"component": "Swatcher_component",
	"img-wrapper": "Swatcher_img-wrapper",
	"imgWrapper": "Swatcher_img-wrapper",
	"selected": "Swatcher_selected",
	"disabled": "Swatcher_disabled",
	"noselect": "Swatcher_noselect"
};