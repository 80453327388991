import styles from './Specifications.module.scss';

import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { RowRails } from '../swiper';
import { makeRichText } from 'utils/render';

const GridRow = ({ data }) => (
  <>
    <div className={styles.fieldName}>
      {data.name}
    </div>
    <RowRails items={data.values.map(v => v.title)} highlightColumns>
      {value => (
        <div className={styles.fieldValue}>
          {value ? makeRichText(value) : <SimpleText textKey="ProductCompare_EmptySpecification" />}
        </div>
      )}
    </RowRails>
  </>
);

GridRow.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
    })).isRequired,
  }).isRequired,
};

export default GridRow;