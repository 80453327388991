import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  pluck,
  switchMap,
  startWith,
  mergeMap,
} from 'rxjs/operators';
import {
  WISH_LIST_ADD_PRODUCT,
  WISH_LIST_ADD_BASKET,
  WISH_LIST_REMOVE_PRODUCT,
  WISH_LIST_MOVE_PRODUCT_TO_BASKET,
  updateWishList,
  productRemovedFromWishList,
} from './actions';
import { basketChangeStarted, basketChangeCompleted, navigateTo } from 'behavior/events';
import {
  addProductMutation,
  addBasketMutation,
  removeProductMutation,
  moveProductToBasketMutation,
} from './queries';
import { trackAddToBasket, getProductsTrackingDataFromLines } from 'behavior/analytics';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { skipIfPreviewWithToast } from 'behavior/preview';

const wishListEpic = (action$, state$, { api, logger }) => {
  const addProductToWishListAction$ = action$.pipe(
    ofType(WISH_LIST_ADD_PRODUCT),
    skipIfPreviewWithToast(state$, { api }),
    pluck('payload'),
    switchMap(input =>
      api.graphApi(addProductMutation, { input }).pipe(
        mergeMap(_ => [updateWishList(Date.now()), unsetLoadingIndicator()]),
        retryWithToast(action$, logger, _ => of(unsetLoadingIndicator())),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  const addBasketToWishListAction$ = action$.pipe(
    ofType(WISH_LIST_ADD_BASKET),
    skipIfPreviewWithToast(state$, { api }),
    switchMap(_ =>
      api.graphApi(addBasketMutation).pipe(
        mergeMap(_ => [updateWishList(Date.now()), unsetLoadingIndicator()]),
        retryWithToast(action$, logger, _ => of(unsetLoadingIndicator())),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  const removeProductFromWishListAction$ = action$.pipe(
    ofType(WISH_LIST_REMOVE_PRODUCT),
    pluck('payload'),
    switchMap(input =>
      api.graphApi(removeProductMutation, { input }).pipe(
        mergeMap(_ => [productRemovedFromWishList(input), unsetLoadingIndicator()]),
        retryWithToast(action$, logger, _ => of(unsetLoadingIndicator())),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  const moveProductToBasketAction$ = action$.pipe(
    ofType(WISH_LIST_MOVE_PRODUCT_TO_BASKET),
    pluck('payload'),
    switchMap(input =>
      api.graphApi(moveProductToBasketMutation, { input, requestModifiedLines: isTrackingEnabled(state$) }).pipe(
        pluck('wishList', 'moveToBasket', 'modifiedLines', 'list'),
        mergeMap(addedLines => {
          const settings = state$.value.settings;
          const actions = [
            basketChangeCompleted(1),
            productRemovedFromWishList(input),
          ];

          const addedProducts = getProductsTrackingDataFromLines(addedLines);
          if (addedProducts && addedProducts.length) {
            actions.push(trackAddToBasket(addedProducts));
          }

          if (settings.basket.redirectOnAdd)
            actions.push(navigateTo(routesBuilder.forBasket()));

          return actions;
        }),
        retryWithToast(action$, logger, _ => of(basketChangeCompleted(0))),
        startWith(basketChangeStarted()),
      ),
    ),
  );

  return merge(
    addProductToWishListAction$,
    addBasketToWishListAction$,
    removeProductFromWishListAction$,
    moveProductToBasketAction$,
  );
};

export default wishListEpic;

function isTrackingEnabled(state$) {
  return state$.value.analytics && state$.value.analytics.isTrackingEnabled;
}