import styles from '../Checkout.module.scss';
import { memo, useRef, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ShippingAddressOption, saveGuest, saveGuestWithPickupStore } from 'behavior/pages/checkout';
import { extractEscapedTemplateFields, validateForm } from 'components/objects/forms';
import StepButtons from './StepButtons';
import { AddressStep as AddressStepSection, GuestAddressStepBody } from '../base/address';
import CheckoutContext from './CheckoutContext';
import scrollIntoView from 'scroll-into-view';
import InvalidAddressMessage from '../base/InvalidAddressMessage';

const GuestAddressStep = ({
  info: {
    profileFields,
    shippingTemplateFields,
    profileTemplateFields,
    shippingAddress,
    stepInvalid,
    isValidShippingAddress,
    invalidProductsForShippingAddress,
    pickupStoreAddresses,
  },
  isActive,
  isCompleted,
}) => {
  const { nextStep } = useContext(CheckoutContext);
  const profileFormikRef = useRef();
  const shippingFormikRef = useRef();
  const [showValidation, setShowValidation] = useState();

  const dispatch = useDispatch();

  const { address: selectedShipping, shippingOption: selectedShippingOption } = shippingAddress;
  const shippingOptionState = useState(selectedShippingOption);
  const [shippingOption] = shippingOptionState;
  const isPickupStore = shippingOption === ShippingAddressOption.PickupStore;

  const [lastPickupSelected, setLastPickupSelected] = useState(() => {
    if (selectedShippingOption === ShippingAddressOption.PickupStore)
      return selectedShipping && selectedShipping.id;
  });

  const onAddressPickupSelection = id => {
    setShowValidation(false);
    setLastPickupSelected(id);
  };

  useEffect(() => {
    setShowValidation(isActive && stepInvalid);
  }, [isActive, stepInvalid]);

  const onStepSubmit = async () => {
    const formValid = await validateForm(profileFormikRef);

    const withShippingAddress = !!shippingFormikRef.current,
      shippingFormValid = !withShippingAddress || (await validateForm(shippingFormikRef));

    if ((isPickupStore) && (!lastPickupSelected && !(pickupStoreAddresses.length === 1))) {
        setShowValidation(true);
        setTimeout(() => scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 200 }), 10);
        return;
    }
   
    if (!formValid || !shippingFormValid) {
      setShowValidation(true);
      const invalidElement = document.querySelector(`.${styles.activeStep} [aria-invalid=true]`);

      if (invalidElement)
        scrollIntoView(invalidElement, { time: 300 }, invalidElement.focus && (() => invalidElement.focus()));

      return;
    }

    const formData = extractEscapedTemplateFields(profileFormikRef.current.values, profileTemplateFields);
    const shippingFormData = withShippingAddress ? extractEscapedTemplateFields(shippingFormikRef.current.values, shippingTemplateFields) : undefined;

    if (isPickupStore) {
        dispatch(saveGuestWithPickupStore(formData, lastPickupSelected || pickupStoreAddresses[0].id, shippingFormData)); 
    }
    else {
      dispatch(saveGuest(formData, shippingFormData));
    }

    nextStep();
  };

  const onAddressFormValidate = async (formikRef, formValid) => {
    const otherFormikRef = formikRef.current === profileFormikRef.current ? shippingFormikRef : profileFormikRef;
    const otherFormValid = !otherFormikRef.current || (await validateForm(otherFormikRef, true));

    if (formValid && otherFormValid)
      setShowValidation(false);
  };

  const onAddressOptionChange = async useSingleAddress => {
    if (!useSingleAddress)
      return;

    const profileFormValid = await validateForm(profileFormikRef, true);
    if (profileFormValid)
      setShowValidation(false);
  };

  return (
    <AddressStepSection
      asLink={!isActive}
      className={`${styles.address} ${isActive ? styles.activeStep : ''}`}
      isGuest
      isCompleted={isCompleted}
    >
      <InvalidAddressMessage isValid={isValidShippingAddress} invalidProducts={invalidProductsForShippingAddress} />
      {isActive &&
        <>
          <GuestAddressStepBody
            shippingOptionState={shippingOptionState}
            profileFields={profileFields}
            profileTemplateFields={profileTemplateFields}
            shippingFields={shippingAddress && shippingAddress.address?.fields}
            selectedPickupAddressId={lastPickupSelected}
            shippingTemplateFields={shippingTemplateFields}
            onPickupStoreSelection={onAddressPickupSelection}
            onAddressFormSubmit={onStepSubmit}
            profileFormikRef={profileFormikRef}
            shippingFormikRef={shippingFormikRef}
            showSelectionValidation={showValidation}
            onAddressFormValidate={onAddressFormValidate}
            onAddressOptionChange={onAddressOptionChange}
            validateFormOnBlur
            pickupStoreAddresses={pickupStoreAddresses}
          />
          <StepButtons onStepSubmit={onStepSubmit} />
        </>
      }
    </AddressStepSection>
  );
};

GuestAddressStep.propTypes = {
  info: PropTypes.shape({
    stepInvalid: PropTypes.bool,
    profileFields: PropTypes.object,
    shippingTemplateFields: PropTypes.array,
    profileTemplateFields: PropTypes.array,
    shippingAddress: PropTypes.shape({
      address: PropTypes.object,
    }),
  }).isRequired,
  isActive: PropTypes.bool,
  isCompleted: PropTypes.bool,
};

export default memo(GuestAddressStep);
