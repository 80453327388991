import { ofType } from 'redux-observable';
import { merge, of, throwError, concat } from 'rxjs';
import {
  switchMap,
  map,
  mapTo,
  filter,
  pluck,
  catchError,
  startWith,
  mergeMap,
} from 'rxjs/operators';
import { combineEpics } from 'utils/rxjs';
import { windowMessage$ } from 'utils/rxjs/eventsObservables';
import { isValidConfiguratorWindowMessage, convertToString } from './util';
import {
  PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_REQUESTED,
  PRODUCT_CONFIGURATOR_EDIT_CONFIGURATION_REQUESTED,
  PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED,
  PRODUCT_CONFIGURATOR_ADD_TO_BASKET_TRIGGERED,
  PRODUCT_CONFIGURATOR_ADD_TO_BASKET_COMPLETED,
  PRODUCT_CONFIGURATOR_REDIRECT_TO_BASKET_TRIGGERED,
  PRODUCT_CONFIGURATOR_MODIFY_BASKET_TRIGGERED,
  receiveNewProductConfiguration,
  receiveEditProductConfiguration,
  productConfigurationFailed,
  receiveProductConfiguratorMessage,
  finishProductConfigurator,
  triggerAddProductConfigurationToBasket,
  completeAddProductConfigurationToBasket,
  triggerRedirectToBasket,
  triggerModifyBasket,
} from './actions';
import {
  createProductConfiguration,
  editProductConfiguration,
  saveProductConfiguration,
  addProductConfigurationToBasket,
} from './queries';
import { basketChangeStarted, basketChangeCompleted, navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import {
  trackAddToBasket,
  trackRemoveFromBasket,
  getConfigurationResultTrackingData,
  getProductsTrackingDataFromLines,
} from 'behavior/analytics';
import { modifyBasket } from 'behavior/basket';

export const productConfiguratorEpic = (action$, state$, { api }) => {
  const startNewAction$ = action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_REQUESTED),
    pluck('payload'),
    switchMap(({ input, updatedById }) => api.graphApi(createProductConfiguration, { input }).pipe(
      pluck('productConfiguration', 'create'),
      map(receiveNewProductConfiguration),
      catchError(_ => concat(of(productConfigurationFailed(updatedById)))),
    )),
  );

  const startEditAction$ = action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_EDIT_CONFIGURATION_REQUESTED),
    pluck('payload'),
    switchMap(({ configurationId, basketLineId, updatedById }) => api.graphApi(editProductConfiguration, { configurationId }).pipe(
      pluck('productConfiguration', 'edit'),
      map(result => receiveEditProductConfiguration({
        ...result,
        basketLineId,
      })),
      catchError(_ => concat(of(productConfigurationFailed(updatedById)))),
    )),
  );

  const messageReceivedAction$ = action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED),
    map(action => ({
      configurationId: state$.value.page.productConfigurator.configurationId,
      message: convertToString(action.payload.message.data),
    })),
    switchMap(
      input => api.graphApi(saveProductConfiguration, { input }).pipe(
        mergeMap(_ => of(finishProductConfigurator(), getBasketAction(state$, input))),
        catchError(e => concat(of(finishProductConfigurator()), throwError(e))),
      ),
    ),
  );

  const addToBasketAction$ = action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_ADD_TO_BASKET_TRIGGERED),
    switchMap(
      action => api.graphApi(addProductConfigurationToBasket, action.payload).pipe(
        pluck('productConfiguration', 'addToBasket'),
        mergeMap(getAddToBasketActions),
        catchError(e => concat(of(basketChangeCompleted()), throwError(e))),
        startWith(basketChangeStarted()),
      ),
    ),
  );

  const redirectToBasketAction$ = action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_ADD_TO_BASKET_COMPLETED),
    filter(_ => state$.value.settings.basket.redirectOnAdd),
    mapTo(triggerRedirectToBasket()),
  );

  const updateBasketAction$ = action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_MODIFY_BASKET_TRIGGERED),
    pluck('payload'),
    switchMap(
      ({ editedBasketLineId, ...params }) => api.graphApi(addProductConfigurationToBasket, params).pipe(
        pluck('productConfiguration', 'addToBasket'),
        mergeMap(result => getModifyBasketActions(result, editedBasketLineId, state$)),
      ),
    ),
  );

  return merge(
    startNewAction$,
    startEditAction$,
    messageReceivedAction$,
    addToBasketAction$,
    redirectToBasketAction$,
    updateBasketAction$,
  );
};

const redirectedToBasketEpic = action$ => {
  const basketRoute = routesBuilder.forBasket();

  return action$.pipe(
    ofType(PRODUCT_CONFIGURATOR_REDIRECT_TO_BASKET_TRIGGERED),
    mapTo(navigateTo(basketRoute)),
  );
};

const productConfiguratorWindowMessagesEpic = (_, state$) => {
  return windowMessage$.pipe(
    filter(msg => isValidConfiguratorWindowMessage(msg, state$.value.page)),
    map(receiveProductConfiguratorMessage),
  );
};

export default combineEpics(productConfiguratorEpic, productConfiguratorWindowMessagesEpic, redirectedToBasketEpic);

function getBasketAction(state$, input) {
  const lineId = state$.value.page.productConfigurator.editedBasketLineId;

  if (lineId)
    return triggerModifyBasket(input.configurationId, lineId);

  return triggerAddProductConfigurationToBasket(input.configurationId);
}

function getAddToBasketActions(configurationResult) {
  const actions = [
    basketChangeCompleted(configurationResult.products.length),
    completeAddProductConfigurationToBasket(),
  ];
  const addedProducts = getConfigurationResultTrackingData(configurationResult);
  if (addedProducts && addedProducts.length) {
    actions.push(trackAddToBasket(addedProducts));
  }

  return actions;
}

function getModifyBasketActions(configurationResult, editedBasketLineId, state$) {
  const actions = [
    modifyBasket([]),
  ];

  const modifiedLine = state$.value.basket.model.productLines.list.find(line => line.id === editedBasketLineId);
  if (modifiedLine) {
    const removedProducts = getProductsTrackingDataFromLines([modifiedLine]);
    if (removedProducts?.length)
      actions.push(trackRemoveFromBasket(removedProducts));
  }

  const addedProducts = getConfigurationResultTrackingData(configurationResult);
  if (addedProducts?.length) {
    actions.push(trackAddToBasket(addedProducts));
  }

  return actions;
}
