import styles from './Swatcher.module.scss';
import { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getPageScrollBarWidth } from 'scroll-lock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { joinClasses } from 'utils/helpers';
import scrollIntoView from 'scroll-into-view';
import { useOnChange, useOnUpdate, useComputedValue, useEffectOnChange } from 'utils/hooks';
import { SimpleText } from 'components/sanaText';
import { isEmpty, isNull, isUndefined } from 'lodash';

const placeholderIndex = -1;
const INPUT_DELAY = 1000;
const SECOND_CHAR_INPUT_DELAY = 250;

const Swatcher = ({
  id,
  items,
  variantComponentsFull,
  value: initialValue,
  className,
  optionClassName = '',
  onChange,
  placeholderTextKey = null,
  shouldReset,
}) => {
  const memoizedItems = useComputedValue(() => items, items);
  const ref = useRef();
  const listBoxRef = useRef();
  const userInputData = useUserInputDataRef();
  const iterateOptions = iterationFunction => {
    let index = 0;
    for (const element of listBoxRef.current.children) {
      if (!element.className.includes('disabled')) {
        iterationFunction(element, index);
        index++;
      }
    }
  };

  const showPlaceholder = !!placeholderTextKey;

  let [selectedIndex, setSelected] = useState(() => getInitialIndex(memoizedItems, initialValue, showPlaceholder));
  let [activeIndex, setActive] = useState(selectedIndex);

  const resetSelectedIndex = index => {
    selectedIndex = activeIndex = index;
    setSelected(index);
    setActive(index);
    listBoxRef.current.style.width = '';
  };

  const handleReset = () => {
    const initialIndex = getInitialIndex(memoizedItems, initialValue, showPlaceholder);
    resetSelectedIndex(initialIndex);
    clearTimeout(userInputData.inputTimeout);
    clearTimeout(userInputData.secondCharInputTimeout);
    userInputData.typedValue = '';
  };

  useOnChange(handleReset, memoizedItems, false);
  useOnChange(handleReset, [shouldReset], false);

  useEffectOnChange(() => {
    const initialIndex = getInitialIndex(memoizedItems, initialValue, showPlaceholder);
    if (selectedIndex === initialIndex)
      return;

    resetSelectedIndex(initialIndex);
  }, [initialValue]);

  useOnUpdate(() => {
    onChange && onChange(selectedIndex !== placeholderIndex ? memoizedItems[selectedIndex].value : null);
  }, [selectedIndex]);

  useEffect(() => {
    iterateOptions((option, i) => option.selected = i === activeIndex);
  }, [activeIndex]);

  useEffect(() => () => {
    clearTimeout(userInputData.inputTimeout);
    clearTimeout(userInputData.secondCharInputTimeout);
  }, []);

  const select = index => {
    setSelected(index);
    setActive(index);
  };  

  const handleClick = ({ target }) => {
    if (activeIndex === target.dataset.index)
      return;

    select(+target.dataset.index);
  };

  const rootElementId = `${id}_select`;
  const elementId = `${id}_listbox`;

  return (
    <div id={rootElementId} className={className} ref={ref}>
      <div
        ref={listBoxRef}
        id={elementId}
        className={styles.componentWrapper}
        aria-labelledby={id}        
        tabIndex="-1"
      >
        {variantComponentsFull.map((option, index) => {
          var memoItem = memoizedItems.filter(x => x.value === option.id && x.name === option.name);
          var isItemAvailable = !isUndefined(memoItem) && memoItem.length > 0;
          var hasThumbnail = !isEmpty(option.thumbnail);

          if (isItemAvailable) {
            var memoItemName = memoItem[0].name;
            var memoizedItemsIndex = memoizedItems.indexOf(memoItem[0]);

            return (
              <div
                className={joinClasses(styles.component, styles.componentSize, styles.noselect, `${optionClassName} ${memoizedItemsIndex === activeIndex ? styles.selected : ''} ${hasThumbnail ? styles.imgWrapper : ''}`)}
                key={memoizedItemsIndex}
                data-index={memoizedItemsIndex}
                id={`${elementId}_${memoizedItemsIndex}`}
                aria-selected={memoizedItemsIndex === activeIndex}
                tabIndex="-1"
                onClick={handleClick}
              >
                {hasThumbnail &&
                  <img
                    src={option.thumbnail}
                    draggable="false"
                    data-index={memoizedItemsIndex}
                    className={styles.textWrapper} />
                }
                {!hasThumbnail &&
                  <span
                    data-index={memoizedItemsIndex}
                    className={styles.textWrapper}>
                    {memoItemName}
                  </span>
                }
              </div>
            );
          }

          return (
            <div key={`disabled${index}`} className={joinClasses(styles.component, styles.componentSize, styles.noselect, styles.disabled, `${hasThumbnail ? styles.imgWrapper : ''}`)}>
              {hasThumbnail &&
                <img src={option.thumbnail} draggable="false" />
              }
              {!hasThumbnail &&
                <span className={styles.textWrapper}>
                  {option.name}
                </span>
              }              
            </div>
          );
        })}
      </div>
    </div>
  );
};

Swatcher.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  className: PropTypes.string,
  optionClassName: PropTypes.string,
  onChange: PropTypes.func,
  placeholderTextKey: PropTypes.string,
  labelId: PropTypes.string,
  autoComplete: PropTypes.string,
  isInvalid: PropTypes.bool,
  shouldReset: PropTypes.any,
};

const memoizedSelect = memo(Swatcher);
memoizedSelect.displayName = 'Swatcher';

export default memoizedSelect;

function useUserInputDataRef() {
  const userInputDataRef = useRef();

  if (!userInputDataRef.current) {
    userInputDataRef.current = {
      typedValue: '',
      inputTimeout: null,
      secondCharInputTimeout: null,
      keyIsHolding: false,
    };
  }

  return userInputDataRef.current;
}

function getInitialIndex(items, initialValue, displayPlaceholder) {
  const defaultIndex = displayPlaceholder ? placeholderIndex : 0;
  if (initialValue === '')
    return defaultIndex;

  const result = items.findIndex(i => i.value === initialValue);
  return result === -1 ? defaultIndex : result;
}