exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductConfigurator_iframe{width:100%;height:calc(100vh - 50px);border:none}.ProductConfigurator_reconfigure-button{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"iframe": "ProductConfigurator_iframe",
	"reconfigure-button": "ProductConfigurator_reconfigure-button",
	"reconfigureButton": "ProductConfigurator_reconfigure-button"
};