import styles from '../page/HeaderContent.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Container } from 'components/primitives/grid';
import { useSelectedFacetsTitle } from '../hooks';
import PageBreadcrumb from 'components/objects/pageBreadcrumb';
import { usePageTitle } from 'components/objects/hooks';

const HeaderContent = ({ categoryId, categoryName, backgroundColor, backgroundImage }) => {
  const category = categoryName || categoryId;
  const facetsTitle = useSelectedFacetsTitle();
  const title = usePageTitle(category ? `${category}${facetsTitle ? ' - ' + facetsTitle : ''}` : null);

  const style = {};
  if (backgroundColor)
    style.backgroundColor = backgroundColor;

  if (backgroundImage)
    style.backgroundImage = `url("${backgroundImage}")`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div
        className={`${styles.coloredHeaderDesktop} ${styles.header} ${backgroundImage ? styles.customBg : ''}`}
        style={style}
      >
        <Container className={styles.center}>
          <PageBreadcrumb noTopMargin />
          <div className={styles.combined}>
            <div className={styles.h1Holder}>
              <h1>
                {category}
                {facetsTitle &&
                  <span className={styles.facetsTitle}>
                    {facetsTitle}
                  </span>
                }
              </h1>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

HeaderContent.propTypes = {
  categoryId: PropTypes.string.isRequired,
  categoryName: PropTypes.string,
};

export default memo(HeaderContent);
