exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".GalleryImage_feature{width:100%;height:320px;box-sizing:content-box;position:relative;margin-bottom:2em}.GalleryImage_feature img{display:block;max-width:100%;max-height:100%;position:absolute;top:0;left:0;right:0;bottom:0;margin:auto;opacity:0;transition:opacity .2s}.GalleryImage_feature .GalleryImage_spinner{position:absolute;top:50%;left:50%;transform:translate(-1em,-1em);opacity:1;transition:opacity .2s .2s}.GalleryImage_feature.fade-in img,.ssr-markup .GalleryImage_feature img{opacity:1}.GalleryImage_feature.fade-in .GalleryImage_spinner,.ssr-markup .GalleryImage_feature .GalleryImage_spinner{transition-delay:0s;opacity:0}.GalleryImage_zoom{position:fixed;z-index:1;background-color:var(--page_BackgroundColor,#fff);box-sizing:content-box;border:2px solid var(--borderColor,#e6e6e6);margin:-2px 0 0 -2px;overflow:hidden;min-width:3em;min-height:3em;opacity:0;pointer-events:none;transition:opacity .2s}.GalleryImage_zoom.fade-in{opacity:1}.GalleryImage_zoom .GalleryImage_spinner{top:50%;left:50%;margin-top:-1em;margin-left:-1em}.GalleryImage_zoom .GalleryImage_spinner,.GalleryImage_zoom img{position:absolute;transition:opacity .2s}.GalleryImage_zoom img{display:block;top:0;left:0;height:auto;-webkit-backface-visibility:hidden;backface-visibility:hidden;opacity:0}.GalleryImage_zoom.ready .GalleryImage_spinner{opacity:0}.GalleryImage_zoom.ready img{opacity:1}.GalleryImage_zoom-lense{position:fixed;z-index:1;background-color:hsla(0,0%,100%,.3);box-sizing:border-box;border:1px solid var(--borderColor,#e6e6e6);width:100px;height:150px;top:0;left:0;pointer-events:none;opacity:0;transition:opacity .2s}.GalleryImage_zoom-lense.fade-in{opacity:1}.GalleryImage_no-image{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"fadeTime": "200",
	"feature": "GalleryImage_feature",
	"spinner": "GalleryImage_spinner",
	"zoom": "GalleryImage_zoom",
	"zoom-lense": "GalleryImage_zoom-lense",
	"zoomLense": "GalleryImage_zoom-lense",
	"no-image": "GalleryImage_no-image",
	"noImage": "GalleryImage_no-image"
};