import styles from './PLP.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';

const TotalChildren = ({ totalChildren }) => {
  if (!totalChildren)
    return null;

  return (
    <div className={styles.productId}>
      <span>
        <SimpleText
          textKey="General_Product_TotalChildren"
          formatWith={[totalChildren]}
          placeholder={<Placeholder className={styles.productIdNamePlaceholder} />}
          formatAsHtml
        />
      </span>
    </div>
  );
};

TotalChildren.propTypes = {
  totalChildren: PropTypes.number
};

export default connectToContext([ProductContext], ({ totalChildren }) => ({ totalChildren }))(
  memo(TotalChildren),
);