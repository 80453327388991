import { Product, Suggestion } from './types';

export const PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED = 'PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED' as const;
export const searchProducts = (keywords: string, count = 10) => ({
  type: PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED,
  payload: { keywords, count },
});

export const PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED = 'PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED' as const;
export const receiveSearchSuggestions = (suggestions: Suggestion[]) => ({
  type: PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED,
  payload: { suggestions },
});

export const PRODUCT_SELECTOR_PRODUCT_REQUESTED = 'PRODUCT_SELECTOR_PRODUCT_REQUESTED' as const;
export const requestProduct = (id: string, isQuickSearch: boolean = false) => ({
  type: PRODUCT_SELECTOR_PRODUCT_REQUESTED,
  payload: { id, isQuickSearch },
});

export const PRODUCT_SELECTOR_PRODUCT_RECEIVED = 'PRODUCT_SELECTOR_PRODUCT_RECEIVED' as const;
export const receiveProduct = (product: Product) => ({
  type: PRODUCT_SELECTOR_PRODUCT_RECEIVED,
  payload: { product },
});

export const PRODUCT_SELECTOR_PRODUCT_CLEARED = 'PRODUCT_SELECTOR_PRODUCT_CLEARED' as const;
export const clearProduct = () => ({
  type: PRODUCT_SELECTOR_PRODUCT_CLEARED,
});

type SearchProductsAction = ReturnType<typeof searchProducts>;
export type ReceiveSearchSuggestionsAction = ReturnType<typeof receiveSearchSuggestions>;
type RequestProductAction = ReturnType<typeof requestProduct>;
export type ReceiveProductAction = ReturnType<typeof receiveProduct>;
type ClearProductAction = ReturnType<typeof clearProduct>;

export type ProductSelectorAction =
  | SearchProductsAction
  | ReceiveSearchSuggestionsAction
  | RequestProductAction
  | ReceiveProductAction
  | ClearProductAction;
