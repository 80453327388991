exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ScrollDownIcon_button{position:absolute;border:none;padding:0;left:50%;transform:translateX(-50%);bottom:10px;background:transparent;cursor:pointer;width:26px;height:62px;opacity:1;z-index:8200000;transition:opacity .5s ease,z-index .01s linear;will-change:opacity,z-index}.ScrollDownIcon_hidden{transition-delay:0s,.5s;opacity:0;z-index:-1}.ScrollDownIcon_chevron{position:absolute;width:26px;height:6px;top:0;opacity:0;transform:scale3d(.5,.5,.5);animation:ScrollDownIcon_move 3s ease-out infinite;animation-delay:3s}.ScrollDownIcon_chevron:first-child{animation:ScrollDownIcon_move 3s ease-out 1s infinite}.ScrollDownIcon_chevron:nth-child(2){animation:ScrollDownIcon_move 3s ease-out 2s infinite}.ScrollDownIcon_chevron:after,.ScrollDownIcon_chevron:before{content:\"\";position:absolute;top:0;height:100%;width:51%;background:#fff}.ScrollDownIcon_chevron:before{left:0;transform:skew(0deg,30deg);box-shadow:-1px 0 0 1px rgba(0,0,0,.15)}.ScrollDownIcon_chevron:after{right:0;width:50%;transform:skew(0deg,-30deg);box-shadow:1px 0 0 1px rgba(0,0,0,.15)}@keyframes ScrollDownIcon_move{25%{opacity:1}33%{opacity:1;transform:translateY(30px)}67%{opacity:1;transform:translateY(40px)}to{opacity:0;transform:translateY(55px) scale3d(.5,.5,.5)}}", ""]);

// exports
exports.locals = {
	"button": "ScrollDownIcon_button",
	"hidden": "ScrollDownIcon_hidden",
	"chevron": "ScrollDownIcon_chevron",
	"move": "ScrollDownIcon_move"
};