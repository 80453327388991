import styles from './Lines.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { useIsPreview } from 'components/objects/preview';
import { ReconfigureButton } from 'components/objects/productConfigurator';

const ActionLinks = ({ productUrl, route, onDelete, productConfiguration, basketLineId }) => {
  const { texts: [viewText, deleteText], loaded } = useSanaTexts(['View', 'Delete'], makeSimpleText);
  const isPreview = useIsPreview();

  if (!loaded)
    return null;

  return (
    <div className={styles.actionLinks}>
      {isPreview
        ? (
          <>
            <Link className={styles.hypView}>{viewText}</Link>
            <span className={styles.separator} />
          </>
        )
        : productUrl && route && (
          <WithProductClickTracking>
            {handleClick => (
              <>
                <Link onClick={handleClick} url={productUrl} to={route} className={styles.hypView}>
                  {viewText}
                </Link>
                <span className={styles.separator} />
              </>
            )}
          </WithProductClickTracking>
        )
      }
      {productConfiguration &&
        <>
          <ReconfigureButton productConfiguration={productConfiguration} basketLineId={basketLineId} />
          <span className={styles.separator} />
        </>
      }
      <button type="button" className={linkStyles.link + ' ' + styles.hypRemove} onClick={onDelete}>
        {deleteText}
      </button>
    </div>
  );
};

ActionLinks.propTypes = {
  productUrl: PropTypes.string,
  route: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  onDelete: PropTypes.func.isRequired,
  productConfiguration: PropTypes.object,
  basketLineId: PropTypes.string,
};

export default ActionLinks;
