exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TermsAndConditions_terms-container{text-align:right;padding-bottom:15px}.TermsAndConditions_terms-container .TermsAndConditions_chb{margin-left:.6em;margin-right:0}.TermsAndConditions_pt-10{padding-top:10px}.TermsAndConditions_btn-close{display:block;margin:30px 0 0 auto}.TermsAndConditions_popup{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"terms-container": "TermsAndConditions_terms-container",
	"termsContainer": "TermsAndConditions_terms-container",
	"chb": "TermsAndConditions_chb",
	"pt-10": "TermsAndConditions_pt-10",
	"pt10": "TermsAndConditions_pt-10",
	"btn-close": "TermsAndConditions_btn-close",
	"btnClose": "TermsAndConditions_btn-close",
	"popup": "TermsAndConditions_popup"
};