import styles from './SimpleNavigation.module.scss';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BaseNavigationItemPropTypes } from './PropTypes';
import NavItem from './NavItem';
import { makeRichText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';

const SimpleNavigation = forwardRef(({
  groupCode: _groupCode,
  items,
  ariaLabel,
  className,
  children,
  isFooterCustom,
}, ref) => {
  const [customFooterText] = useSanaTexts(['CustomFooterSanaText']).texts;
  const showCustomFooter = (customFooterText && isFooterCustom);
  return (
    <>
      {children}
      <nav aria-label={ariaLabel} className={`${showCustomFooter ? styles.flex : ''}`}>
        <ul className={`${className} ${styles.columns} ${showCustomFooter ? styles.footerCustom : ''}`} ref={ref}>
          {items.map((item, index) => <NavItem key={index} item={item} className={styles.item} />)}
        </ul>
        {showCustomFooter && <div className={className}>{makeRichText(customFooterText)}</div>}
      </nav>
    </>
  );
});

SimpleNavigation.propTypes = {
  items: PropTypes.arrayOf(BaseNavigationItemPropTypes),
  groupCode: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  isFooterCustom: PropTypes.bool,
};

export default connect(
  ({ navigation }, { groupCode }) => ({ items: navigation[groupCode] }),
  null,
  null,
  { forwardRef: true },
)(SimpleNavigation);