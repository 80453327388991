import { memo, useState, useRef, useEffect } from 'react';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText, renderHTML } from 'utils/render';
import { DangerAlert } from 'components/primitives/alerts';
import { VerticalSliding } from 'components/primitives/transitions';
import scrollIntoView from 'scroll-into-view';

const InvalidAddressMessage = ({ isValid, invalidProducts }) => {
  const [expanded, setExpanded] = useState(true);
  const timeoutRef = useRef();
  const hasInvalidProducts = (invalidProducts?.length ?? 0) > 0;

  const { texts: [template, ...sanaTextTitles], loaded } = hasInvalidProducts ?
    useSanaTexts(['InvalidAddressMessage', ...(invalidProducts?.map(p => p) ?? [])]) : useSanaTexts(['InvalidAddressMessage_NoProducts', ...(invalidProducts?.map(p => p) ?? [])]) ;
  const show = loaded && !isValid;

  useEffect(() => {
    if (!show)
      return;

    if (!expanded)
      setExpanded(true);

    const messageElement = document.getElementById('invalidAddressMsg');
    messageElement && scrollIntoView(messageElement, { time: 300, align: { top: 0.25 } });
    timeoutRef.current = setTimeout(() => setExpanded(false), 5000);

    return () => clearTimeout(timeoutRef.current);
  }, [isValid, invalidProducts, loaded]);

  if (!show)
    return null;

  const invalidProductIds = (sanaTextTitles[0] ? sanaTextTitles : (invalidProducts?.map(productId => productId) ?? []))
    .flatMap(productId => [renderHTML(`'${productId}'`), ', ']);

  if (hasInvalidProducts)
    invalidProductIds.pop();

  return (
    <VerticalSliding
      expanded={expanded}
      role="alertdialog"
      aria-labelledby="invalidAddressMsg"
      duration={350}
      timingFunction="ease"
    >
      <DangerAlert>
        <span id="invalidAddressMsg">
          {makeRichText(template, hasInvalidProducts ? [invalidProductIds] : null)}
        </span>
      </DangerAlert>
    </VerticalSliding>
  );
};
export default memo(InvalidAddressMessage);
