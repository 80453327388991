import { initComponent } from 'behavior/pages/helpers';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { of } from 'rxjs';
import { map, first, switchMap } from 'rxjs/operators';
import { sortByIds, buildSpecificationsModel } from './util';
import { getProductIdsToCompare } from 'behavior/productComparison/util';
import { initSystemPageContent } from '../system';

import {
  loadPageQuery,
  loadPageWithProductsQuery,
} from './queries';

export default ({
  params: {
    productIds = [],
    previewToken,
  },
  options,
}, state$, { api }) => {
  if (previewToken) {
    return state$.pipe(
      first(state => state.settings.loaded),
      switchMap(({ settings: { product: { productComparison } } }) => api.graphApi(loadPageQuery).pipe(
        map(({ pages: { productComparison: page } }) => {
          if (!page)
            return null;

          const products = Array.from(Array(Math.min(5, productComparison.maxProductsToCompare))).map((_, index) => ({
            id: index.toString(),
            reviews: {},
          }));
          page.component = PageComponentNames.ProductComparison;
          page.products = products;
          page.specifications = productComparison.specifications.map(spec => ({
            ...spec,
            values: Array.from(Array(products.length)),
          }));

          return { page };
        }),
        initSystemPageContent(),
      )),
    );
  }

  if (options && options.keepPreviousState)
    return of({ page: state$.value.page });

  return state$.pipe(
    first(state => state.settings.loaded),
    switchMap(
      ({ settings: { product }, analytics }) => product && product.productComparison && product.productComparison.isEnabled
        ? loadPage(
          api,
          getProductIdsToCompare(productIds, product.productComparison),
          product.productComparison,
          analytics,
          state$.value.insiteEditor.initialized,
        ) : of(null),
    ),
    initSystemPageContent(),
  );
};

function loadPage(api, ids, settings, analytics, isInsiteEditor) {
  const { query, params } = createApiRequest(ids, analytics, isInsiteEditor);

  return api.graphApi(query, params).pipe(
    map(result => mapResult(result, ids, settings)),
    initComponent(PageComponentNames.ProductComparison),
  );
}

function mapResult(
  {
    pages: {
      productComparison: page,
    },
    catalog,
  },
  ids,
  settings,
) {
  if (!page)
    return null;

  const products = catalog && sortByIds(catalog.products.products, ids);
  return {
    ...page,
    products,
    specifications: buildSpecificationsModel(settings, products),
  };
}

function createParamsForProducts(ids) {
  return {
    options: {
      ids,
      page: {
        size: ids.length,
      },
    },
  };
}

function createApiRequest(ids, analytics, isInsiteEditor) {
  if (ids && ids.length) {
    return {
      query: loadPageWithProductsQuery({ isInsiteEditor }),
      params: {
        ...createParamsForProducts(ids),
        loadCategories: analytics && analytics.isTrackingEnabled,
      },
    };
  }

  return {
    query: loadPageQuery,
  };
}