import styles from './TermsAndConditions.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SanaForm } from 'components/objects/forms';
import { HiddenSubmitButton } from 'components/primitives/form';
import CheckboxFormGroup from './CheckboxFormGroup';
import Info from './Info';
import { formatText } from 'utils/formatting';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText, makeRichText } from 'utils/render';

const TermsAndConditions = ({ submitButtonTextKey, formikRef, onSubmit }) => {
  const hidden = false; 
  const { termsError, loaded, title, ...infoTexts } = useTermsAndConditionsTexts(hidden, submitButtonTextKey);

  const initialValues = useMemo(() => ({
    acceptAditionalTerms: false,
  }), []);

  if (!loaded)
    return null;

  if (hidden)
    return <div className={styles.termsContainer}>{title} <Info {...infoTexts} /></div>;

  return (
    <SanaForm
      name="termsAndConditions"
      formikRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <CheckboxFormGroup fieldName="acceptAditionalTerms" errorText={termsError} title={title} infoTexts={infoTexts} />
      <HiddenSubmitButton />
    </SanaForm>
  );
};

TermsAndConditions.propTypes = {
  submitButtonTextKey: PropTypes.string.isRequired,
  formikRef: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = ({ settings }) => ({
  visibility: settings.checkout &&
    settings.checkout.termsAndConditionsVisibility,
});

export default connect(mapStateToProps)(TermsAndConditions);

function useTermsAndConditionsTexts(hidden, submitButtonTextKey) {
  const {
    texts: [
      termsErrorText,
      titleText,
      submitButtonText,
      termsMessageText,
      titleLinkText,
      popupHeaderText,
      popupBodyText,
    ],
    loaded,
  } = useSanaTexts([
    !hidden && 'OrderOverview_AgreeAdditionalTermsError',
    !hidden && 'OrderOverview_AdditionalTerms',
    hidden && `ButtonText_${submitButtonTextKey}`,
    hidden && 'OrderOverview_AdditionalTermsMessage',
    'TermsAndConditionsAdditional',
    'TermsAndConditionsAdditional_Title',
    'TermsAndConditionsAdditional_Body',
  ]);

  return {
    termsError: makeSimpleText(termsErrorText),
    title: hidden ? formatText(termsMessageText, [submitButtonText]) : makeSimpleText(titleText),
    linkText: makeSimpleText(titleLinkText),
    popupHeader: makeSimpleText(popupHeaderText),
    popupBody: makeRichText(popupBodyText),
    loaded,
  };
}
