import styles from '../Checkout.module.scss';
import { useEffect, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { AddressStep as AddressStepSection, GuestAddressStepBody } from '../base/address';
import { selectPickupStore } from 'behavior/pages/checkout/actions';
import CheckoutContext from './CheckoutContext';
import { extractEscapedTemplateFields, validateForm } from 'components/objects/forms';
import { ShippingAddressOption, Steps } from 'behavior/pages/checkout';
import { saveGuest } from 'behavior/pages/checkout';
import { useOnChange } from 'utils/hooks';
import scrollIntoView from 'scroll-into-view';

const GuestAddressStep = ({ info, isCompleted }) => {
  const {
    profileFields,
    profileTemplateFields,
    shippingTemplateFields,
    shippingAddress=null,
    pickupStoreAddresses,
  } = info;

  const profileFormikRef = useRef();
  const shippingFormikRef = useRef();
  shippingFormikRef.current = null;

  const { setLoading, registerStep, setStepCompletion } = useContext(CheckoutContext);
  const { address: selectedShipping, shippingOption: selectedShippingOption } = shippingAddress;

  const shippingOptionState = useState(selectedShippingOption);
  const [shippingOption, setShippingOption] = shippingOptionState;

  useOnChange(() => setShippingOption(selectedShippingOption), [selectedShippingOption], false);

  const lastSelectedPickupRef = useRef();
  const lastCustomFieldsRef = useRef();
  lastCustomFieldsRef.current = profileFields;

  if (selectedShippingOption === ShippingAddressOption.PickupStore)
    lastSelectedPickupRef.current = selectedShipping && selectedShipping.id;

  const shippingOptionRef = useRef();
  shippingOptionRef.current = shippingOption;
  const [showSelectionValidation, setShowSelectionValidation] = useState();
  useEffect(() => {
    const validateStep = async () => {
      const formValid = await validateForm(profileFormikRef);
      const withShippingAddress = !!shippingFormikRef.current;
      setShowSelectionValidation(true);
      const shippingFormValid = !withShippingAddress || (await validateForm(shippingFormikRef));

      if (shippingOptionRef.current === ShippingAddressOption.PickupStore) {
        if (!lastSelectedPickupRef.current) {
          setShowSelectionValidation(true);
          scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 200 });
          return false;
        }
      }
      if (!formValid || !shippingFormValid) {
        setShowSelectionValidation(true);
        scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 200 });
      }

      return formValid && shippingFormValid;
    };

    registerStep(Steps.Address, styles.address, [Steps.Address], validateStep);
  }, []);

  const dispatch = useDispatch();

  const onAddressFormSubmit = async () => {

    const formValid = await validateForm(profileFormikRef);
    const withShippingAddress = !!shippingFormikRef.current;

    const shippingFormValid = !withShippingAddress || (await validateForm(shippingFormikRef));

    if (!formValid || !shippingFormValid)
      return;

    setLoading(Steps.Address);
    const formData = extractEscapedTemplateFields(profileFormikRef.current.values, profileTemplateFields);
    const shippingFormData = withShippingAddress ? extractEscapedTemplateFields(shippingFormikRef.current.values, shippingTemplateFields) : undefined;
    dispatch(saveGuest(formData, shippingFormData));
  };

  const onAddressFormValidate = async (formikRef, formValid) => {
    const otherFormikRef = formikRef.current === profileFormikRef.current ? shippingFormikRef : profileFormikRef;
    const otherFormValid = !otherFormikRef.current || (await validateForm(otherFormikRef, true));

    if (formValid && otherFormValid)
      setShowSelectionValidation(false);
  };

  const onAddressOptionChange = async useSingleAddress => {
    if (!useSingleAddress)
      return;

    const profileFormValid = await validateForm(profileFormikRef, true);
    if (profileFormValid)
      setShowSelectionValidation(false);
  };

  const onPickupStoreSelection = id => {
    setShowSelectionValidation(false);
    dispatch(selectPickupStore(id));
    setLoading(Steps.Address);
  };

  const onOptionChange = option => {
    setShowSelectionValidation(false);

    if (option === selectedShippingOption)
      return;

    if (option === ShippingAddressOption.PickupStore) {
      if (lastSelectedPickupRef.current || pickupStoreAddresses.length === 1) {
        setLoading(Steps.Address);
        dispatch(selectPickupStore(lastSelectedPickupRef.current || pickupStoreAddresses[0].id));
      }
    } else if (lastCustomFieldsRef.current) {
      setLoading(Steps.Address);
      dispatch(saveGuest(lastCustomFieldsRef.current));
    }
  };

  useEffect(() => {

    if (shippingOption === ShippingAddressOption.Billing)
      setStepCompletion(Steps.Address, true);
    else if (shippingOption === ShippingAddressOption.PickupStore) {
      setStepCompletion(Steps.Address, !!lastSelectedPickupRef.current);
    }

  }, [shippingOption, selectedShippingOption]);

  return (
    <AddressStepSection
      className={styles.address}
      isGuest
      isCompleted={isCompleted}
    >
      <GuestAddressStepBody
        shippingOptionState={shippingOptionState}
        profileFields={profileFields}
        profileTemplateFields={profileTemplateFields}
        selectedPickupAddressId={lastSelectedPickupRef.current}
        shippingFields={shippingAddress && shippingAddress.address?.fields}
        shippingTemplateFields={shippingTemplateFields}
        onOptionChange={onOptionChange}
        onPickupStoreSelection={onPickupStoreSelection}
        onAddressFormSubmit={onAddressFormSubmit}
        onAddressFormValidate={onAddressFormValidate}
        profileFormikRef={profileFormikRef}
        shippingFormikRef={shippingFormikRef}
        submitFormOnBlur
        onAddressOptionChange={onAddressOptionChange}
        showLoading
        showSelectionValidation={showSelectionValidation}
        pickupStoreAddresses={pickupStoreAddresses}
      />
    </AddressStepSection>
  );
};

GuestAddressStep.propTypes = {
  info: PropTypes.shape({
    profileFields: PropTypes.object,
    profileTemplateFields: PropTypes.array,
    shippingTemplateFields: PropTypes.array,
    shippingAddress: PropTypes.shape({
      address: PropTypes.object,
    }),
  }),
  isCompleted: PropTypes.bool,
};

export default GuestAddressStep;
