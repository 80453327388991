import styles from 'components/objects/product/Details.module.scss';
import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { useProductContext } from './hooks';
import { useSelector } from 'react-redux';
import { Link } from '../../primitives/links'

const ProductTitle = () => {
  const { product } = useProductContext();
  let { id, title, isParent, isChild, parentUrl, tradeMark } = product || {};
  title = useSelector(state => state.page.header) || title;

  if (!id && !title)
    return null;

  const namePlaceholder = <Placeholder className={styles.namePlaceholder} />;

  return (
    <div className={styles.overview}>
      <div>
        <span className={styles.productTrademark}>{tradeMark}</span>
      </div>
      <div>
        <span className={styles.productTitle}>{title}</span>
      </div>
      {isChild && parentUrl &&
        <div>
          <span>
            <a href={parentUrl} target="_blank">
              <SimpleText textKey="ViewVariantsChildLink" />
            </a>
          </span>
        </div>
      }
      {!isParent &&
        <div className={styles.productId}>
          <span className={styles.name}>
            <SimpleText textKey="General_Product_Id" placeholder={namePlaceholder} />
          </span>
          <span className={styles.value}>{id}</span>
        </div>
      }
    </div>
  );
};

export default memo(ProductTitle);