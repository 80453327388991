import { useInsiteEditorTexts } from 'components/visualDesigner/insiteEditor';
import { useSelector } from 'react-redux';

const useEditableSpecifications = specFields => {
  if (!useSelector(state => state.insiteEditor.initialized))
    return specFields;
    
  return useInsiteEditorTexts(specFields, (field, getText) => {
    return {
      ...field,
      name: getText(`ProductProperty_${field.key}`, field.name),
      values: field.values.map(v => ({
        ...v,
        title: v.value ? getText(`ProductSpecification_Product_${field.key}_${v.value}`, v.title) : '',
      })),
    };
  }, { textOnly: true, isConstructedTexts: true });
};

export default useEditableSpecifications;
