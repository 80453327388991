import styles from './Basket.module.scss';
import PropTypes from 'prop-types';
import Lines from './lines';
import { useUpdateContext } from './updateContext';
import { ProductConfigurator } from 'components/objects/productConfigurator';

const BasketContent = ({ productLines, serviceLines, lastModifiedLineId }) => {
  const { isB2BPreset, submit } = useUpdateContext();

  return (
    <>
      <form className={`${styles.form}${isB2BPreset ? ` ${styles.b2BPreset}` : ''}`} onSubmit={submit}>
        {/*Submit button is required to trigger submit by Enter/assistive technologies. */}
        <input type="submit" className="visually-hidden" tabIndex="-1" aria-hidden />
        <Lines
          productLines={productLines}
          serviceLines={serviceLines}
          lastModifiedLineId={lastModifiedLineId}
        />
      </form>
      <ProductConfigurator />
    </>
  );
};

BasketContent.propTypes = {
  productLines: PropTypes.object,
  serviceLines: PropTypes.array,
  lastModifiedLineId: PropTypes.string,
};

export default BasketContent;