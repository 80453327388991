import styles from '../OrderBox.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useContext } from 'react';
import { connect } from 'react-redux';
import { Placeholder } from 'components/primitives/placeholders';
import { SimpleText } from 'components/sanaText';
import QuantityBox from './QuantityBox';
import VariantsButton from 'components/objects/productList/b2b/VariantsButton';
import ViewProductButton from '../b2c/ViewProductButton';
import { AbilityTo } from 'behavior/user/constants';
import { generateKey } from 'utils/helpers';
import { addProducts } from 'behavior/basket';
import { SanaButton } from 'components/primitives/buttons';
import ListContext from './ListContext';
import scrollIntoView from 'scroll-into-view';

const OrderBox = ({
  product,
  className = '',  
  gridMode,
  abilities,
  addProducts,
  preselectedAgreementLineId,
}) => {
  if (!abilities[AbilityTo.OrderProducts])
    return null;

  const {
    id,
    isOrderable,
    hasVariants,
    productConfiguratorInfo,
    isParent,
  } = product;

  const [componentId] = useState(generateKey);
  const [disabled, setDisabled] = useState(false);
  const { quantities, resetQuantities, updateQuantity } = useContext(ListContext);
  let content = null;
  const placeholder = <Placeholder className="placeholder" />;

  const addToBasket = useCallback((quantities, resetQuantities, updateQuantity) => {
    if (disabled)
      return;

    setDisabled(true);

    const value = quantities.get(id);
    if (value === undefined || !value.quantity.isValid) {
      const inputId = `${id}_qty`;
      const invalidQuantityInput = document.getElementById(inputId);

      scrollIntoView(invalidQuantityInput, { time: 200 }, () => invalidQuantityInput.focus());

      document.getElementById(inputId).focus();
      return;
    }

    const { quantity, uomId } = value;
    addProducts([{
      productId: id,
      uomId,
      quantity: quantity.value,
      salesAgreementLineId: preselectedAgreementLineId,
    }], componentId);

    updateQuantity(id, uomId, null);

  }, []);

  if (isParent) {
    content = (
      <ViewProductButton
        textKey="SelectVariantsParent"
        className={`${btnStyles.btnSmall} btn-action`}
        product={product}
        placeholder={placeholder}
      />
    );
  } else if (isOrderable == null) {
    content = placeholder;
  } else if (isOrderable === false) {
    const isSimpleProduct = !hasVariants && !productConfiguratorInfo.isProductConfigurable;
    content = (
      <>
        <span className="msg-not-available">
          <SimpleText textKey="Product_NotAvailable" placeholder={placeholder} />
        </span>
        {isSimpleProduct && <QuantityBox abilities={abilities} product={product} gridMode={gridMode} />}
      </>
    );
  } else if (productConfiguratorInfo.isProductConfigurable) {
    content = (
      <ViewProductButton
        textKey="ConfigureProduct"
        className={`${btnStyles.btnSmall} btn-configure`}
        titleTextKey="ConfigureProduct_ListPage"
        product={product}
        placeholder={placeholder}
      />
    );
  } else if (hasVariants) {
    content = <VariantsButton abilities={abilities} product={product} />;
  } else {
    content = (
      <>
      <QuantityBox abilities={abilities} product={product} gridMode={gridMode} />
      <SanaButton className={`${btnStyles.btnAction} ${btnStyles.btnSmall} btn-action`}
        textKey="AddToBasket"
        onClick={addToBasket.bind(this, quantities, resetQuantities, updateQuantity)}
        placeholder={placeholder}
      />
      </>
    );
  }

  return (
    <div className={`${styles.orderBox} ${className}`}>
      {content}
    </div>
  );
};

OrderBox.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isOrderable: PropTypes.bool,
    hasVariants: PropTypes.bool,
    productConfiguratorInfo: PropTypes.object,
    isParent: PropTypes.bool,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
    uoms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
  }),
  className: PropTypes.string,
  abilities: PropTypes.object.isRequired,
  gridMode: PropTypes.bool,
  preselectedAgreementLineId: PropTypes.string,
};

export default connect(
  ({
    page: { salesAgreement },
  }) => ({ preselectedAgreementLineId: salesAgreement?.preSelectedLine?.id }),
  { addProducts },
)(OrderBox);