import styles from '../Lines.module.scss';
import { useUpdateContext } from '../../updateContext';
import LineTemplatePropTypes from './LineTemplatePropTypes';

const LineTemplate = ({
  productInfo,
  agreementTerm,
  productActionBlock,
  actionLinks,
  quantityBox,
  uomLabel,
  priceNode,
  productDiscount,
  subTotal,
  className = null,
  isGroupedLine,
  id,
}) => {
  const { isB2BPreset } = useUpdateContext();

  return (
    <tr className={className} id={id}>
      {(isB2BPreset || productActionBlock) && (
        <td className={styles.colProductAction} aria-hidden={!isGroupedLine}>
          {productActionBlock}
        </td>
      )}
      <td className={styles.colProductInfo} aria-describedby="basketProduct">
        {productInfo}
        {agreementTerm}
        {actionLinks}
      </td>
      <td className={styles.colPrice} aria-describedby="basketPrice" aria-hidden={!priceNode}>
        {priceNode}
      </td>
      <td className={styles.colQuantity} aria-describedby="basketQuantity">
        {quantityBox}
      </td>
      {uomLabel &&
        <td className={styles.colUom} aria-describedby="basketUom">
          {uomLabel}
        </td>
      }
      <td className={styles.colTotal} aria-describedby="basketTotal" aria-hidden={!subTotal}>
        {subTotal}
        {productDiscount && (
          <>
            <br />
            {productDiscount}
          </>
        )}
      </td>
    </tr>
  );
};

LineTemplate.propTypes = LineTemplatePropTypes;

export default LineTemplate;