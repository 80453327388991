import { PageComponentNames } from 'behavior/pages';
import { RouteName } from 'routes';
import { decapitalize } from 'utils/helpers';
import { loadSystemPageQuery } from '../system/queries';
import { pluckAsPage, getBackTo, initComponent } from '../helpers';
import { initSystemPageContent } from '../system';
import { of } from 'rxjs';
import { tap, switchMap, pluck, first } from 'rxjs/operators';
import { StoreType } from 'behavior/settings';

const loginPageName = decapitalize(RouteName.Login);

export default (routeData, state$, { api }) => {
  return state$.pipe(
    pluck('settings'),
    first(settings => settings.loaded),
    switchMap(
      settings => settings.storeType === StoreType.Closed
        ? loadClosedStoreLoginPage(routeData, state$)
        : loadSystemLoginPage(routeData, state$, api),
    ),
  );
};

function loadClosedStoreLoginPage(routeData, state$) {
  return of({
    emptyLayout: true,
    backTo: _getBackTo(routeData, state$),
  }).pipe(
    initComponent(PageComponentNames.ClosedStoreLogin),
  );
}

function loadSystemLoginPage(routeData, state$, api) {
  return api.graphApi(loadSystemPageQuery(loginPageName)).pipe(
    pluckAsPage(loginPageName, PageComponentNames.Login),
    tap(({ page }) => {
      if (page)
        page.backTo = routeData.options && routeData.options.backTo || _getBackTo(routeData, state$);
    }),
    initSystemPageContent(),
  );
}

function _getBackTo(routeData, state$) {
  return getBackTo(state$, [
    RouteName.Login,
    RouteName.Registration,
    RouteName.ResetPassword,
    RouteName.ForgotPassword,
  ], routeData.params && routeData.params.language);
}
